<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>調査トップ</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn class="" color="primary" link to="/" :disabled="loading">月次報告データ(8号・9号・輸入物資)</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="" color="primary" width="120" link to="/factfinding_list" :disabled="loading">実態調査</v-btn>
      </v-col>
      <v-col>
        <v-btn class="" color="primary" width="120" link to="/" :disabled="loading">電力調査</v-btn>
      </v-col>
      <v-col>
        <v-btn class="" color="primary" width="120" link to="/" :disabled="loading">冷媒調査</v-btn>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
import Xlsx from '../mixins/excel_download';

export default {
  components: {
  },

  mixins: [Xlsx],

  data: () => ({
    title: "",

    // 検索条件
    searchCondition: {
      targetMonth: "",
      facility: null,
    },

    // 設備リスト
    facilityList: [],

    tableItems: [],

    lineId: null,
    loading: false,
  }),

  // ライフサイクルフック
  created: function () {

  },

  mounted: async function () {

  },

  methods: {

  },

  watch: {

  },
};
</script>

<style scoped>

</style>
