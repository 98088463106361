import * as XLSX from "xlsx";

export default {
  methods: {
    
    exceldownload(xlsx_name, table_id) {
      const elt = document.querySelector(table_id);
      const wb = XLSX.utils.table_to_book(elt, xlsx_name);
      XLSX.writeFile(wb, xlsx_name);
    },

    exceldownload2(xlsx_name, sheetArray) {
      // ワークブックを作成
      const wb = XLSX.utils.book_new();

      sheetArray.forEach(element => {
        // テーブルデータを取得
        const elt = document.querySelector(element[0]);
        // シートを作成
        const sheet = XLSX.utils.table_to_sheet(elt);
        // ワークブックにシートを追加
        XLSX.utils.book_append_sheet(wb, sheet, element[1]);
      });

      // XLSXファイルを出力
      XLSX.writeFile(wb, xlsx_name);
    },

  },
};
